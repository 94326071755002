import { lazy, Suspense } from 'react'
import { DefaultToastOptions, Toaster } from 'react-hot-toast'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'

import { IndexPage } from './pages/IndexPage'
import NotFoundPage from './pages/NotFoundPage'
import Loading from './routes/Loading'

const DashRoutes = lazy(() => import('./routes/DashRoutes'))
const AccessRoutes = () => import('./routes/AccessRoutes')

const toastOptions: DefaultToastOptions = {
  success: {
    duration: 8000,
  },
  error: {
    duration: 12000,
  },
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<IndexPage />} />
      <Route
        path="/-/*"
        element={
          <Suspense fallback={<Loading />}>
            <DashRoutes />
          </Suspense>
        }
        errorElement={<>Ha ocurrido un error. Favor recarge la página.</>}
      />
      <Route path="/a/*" lazy={AccessRoutes} />
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  )
)

const App = () => {
  return (
    <>
      <Toaster position="top-left" toastOptions={toastOptions} />
      <RouterProvider router={router} />
    </>
  )
}

export default App
