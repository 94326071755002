import { ResponseError } from 'app/types'
import toast from 'react-hot-toast'

export const notifyResponse = {
  error: (response: ResponseError, overrideMessage?: string) => {
    toast.error(
      overrideMessage ||
        translateErrorResponse(response) ||
        'Ha ocurrido un error desconocido'
    )
  },
  success: (message: string) => {
    toast.success(message)
  },
}

export const notify = {
  error: (message: string) => toast.error(message),
  success: (message: string) => toast.success(message),
}

export const translateErrorResponse = (response: ResponseError) => {
  if (response.message.includes('googleapis')) {
    return null
  }
  const errors = Object.values(response.errors ?? {})
    .flat()
    .map((error) => `- ${error}`)

  if (errors.length > 1) {
    errors.unshift('Errores:')
  }

  return errors.length > 1 ? errors?.join('\n') : response.message
}
