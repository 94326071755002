import { notifyResponse } from 'app/helpers/notification'
import Axios from 'axios'

const apiInstance = Axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_BACKEND_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
  withXSRFToken: true,
})

apiInstance.interceptors.response.use(undefined, (error) => {
  if (![403, 404].includes(error.response.status))
    notifyResponse.error(error.response.data)
  if (error.response.status === 403)
    notifyResponse.error({
      ...error.response.data,
      message:
        'Su usuario no tiene permisios suficientes para realizar esta acción.',
    })
  return Promise.reject(error)
})

export default apiInstance
