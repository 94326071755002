import api from 'app/lib/api'
import { GenericObject, TFERoute, TFRouteValue } from 'app/types'
import { isNil, pickBy } from 'ramda'
import { v4 as uuidv4, validate as uuidValidate } from 'uuid'

export const setCSRF = () => api.get('/csrf')

const FE_ROUTE_MAP: { [key in TFERoute]: TFRouteValue } = {
  login: () => '/a/login',
  register: () => '/a/registro',
  passwordReset: () => '/a/restablecer-contrasena',
  forgotPassword: () => '/a/recuperar-contrasena',
  verify: () => '/a/verificar-cuenta',
  dashboard: () => '/-/',
  // --
  invoices: () => '/-/ingresos/facturas',
  invoiceNew: (_, searchParams) => {
    const searchQuery = objectToURLQueryParamString(searchParams || {})
    return `/-/ingresos/facturas/nueva${searchQuery}`
  },
  invoiceModify: (params) =>
    params?.id ? `/-/ingresos/facturas/${params.id}/modificar` : '',
  invoiceView: (params) =>
    params?.id ? `/-/ingresos/facturas/${params.id}/ver` : '',

  quotes: () => '/-/ingresos/cotizaciones',
  quoteNew: (_, searchParams) => {
    const searchQuery = objectToURLQueryParamString(searchParams || {})
    return `/-/ingresos/cotizaciones/nueva${searchQuery}`
  },
  quoteModify: (params) =>
    params?.id ? `/-/ingresos/cotizaciones/${params.id}/modificar` : '',
  quoteView: (params) =>
    params?.id ? `/-/ingresos/cotizaciones/${params.id}/ver` : '',

  payments: () => '/-/ingresos/pagos',
  paymentNew: () => '/-/ingresos/pagos/nuevo',
  paymentView: (params) =>
    params?.id ? `/-/ingresos/pagos/${params.id}/ver` : '',
  paymentModify: (params) =>
    params?.id ? `/-/ingresos/pagos/${params.id}/modificar` : '',

  creditNotes: () => '/-/ingresos/notas-credito',
  creditNoteNew: () => '/-/ingresos/notas-credito/nueva',
  creditNoteView: (params) =>
    params?.id ? `/-/ingresos/notas-credito/${params.id}/ver` : '',
  creditNoteModify: (params) =>
    params?.id ? `/-/ingresos/notas-credito/${params.id}/modificar` : '',

  userProfile: () => '/-/mi-perfil',

  catalog: () => '/-/catalogo',
  productNew: () => '/-/catalogo/nuevo',
  productModify: (params) =>
    params?.id ? `/-/catalogo/${params.id}/modificar` : '',

  // Configuración
  config: () => '/-/configuracion',

  configGeneralInfo: () => '/-/configuracion/informacion-general',

  NCFConfigNew: () => '/-/configuracion/ncf/nuevo',
  NCFConfigModify: (params) =>
    `/-/configuracion/ncf/${params?.id || 0}/modificar`,
  NCFConfig: () => '/-/configuracion/ncf',

  configTaxNew: () => '/-/configuracion/impuestos/nuevo',
  configTaxModify: (params) =>
    `/-/configuracion/impuestos/${params?.id || 0}/modificar`,
  configTaxes: () => '/-/configuracion/impuestos',

  configRetentionNew: () => '/-/configuracion/retenciones/nueva',
  configRetentionModify: (params) =>
    `/-/configuracion/retenciones/${params?.id || 0}/modificar`,
  configRetentions: () => '/-/configuracion/retenciones',

  // ---
  contacts: () => '/-/contactos',
  contactNew: () => '/-/contactos/nuevo',
  contactModify: (params) =>
    params?.id ? `/-/contactos/${params.id}/modificar` : '',
}

export const getRoutePath = (
  route: TFERoute,
  params?: { id: string },
  searchParams?: Record<string, string>
) => FE_ROUTE_MAP[route](params, searchParams)

export const objectToURLQueryParamString = (
  object: Record<string, string | null>
) => {
  const params = new URLSearchParams(objectRemoveEmpty(object))
  return isEmpty(object) ? '' : `?${params.toString()}`
}
export const clientRedirect = (url: string) => {
  window.location.href = url
}

export const getBackendUrl = (route: string) => {
  return `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/${route}`
}

export const redirectToGoogleLogin = () => {
  clientRedirect(getBackendUrl('login/google'))
}

export const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noreferrer')
}

export const isEmpty = (val: any) => {
  if (typeof val === 'number' && val !== 0) {
    return false
  }

  return val == null || !(Object.keys(val) || val).length
}

export const isBoolean = (value: unknown) => typeof value === 'boolean'

export const isNumber = (value: unknown) => typeof value === 'number'

export const uuid = () => uuidv4()
export const isUuidValid = (uuid: string) => uuidValidate(uuid)

export const isValidURL = (value: string) => value?.startsWith('https://')

export const isDefined = (value: unknown) =>
  value != null && typeof value !== 'undefined'

export const objectRemoveByKeys = (keys: string[], object: GenericObject) => {
  return pickBy((_, key) => !keys.includes(key), object) as any
}

export const objectRemoveEmpty = (object: GenericObject) => {
  return pickBy((v) => !isNil(v), object) as any
}

export const transformToFormData = (object: GenericObject) => {
  if (!object || Object.keys(object).length === 0) return object

  const formData = new FormData()

  for (const key in object) {
    const value = object[key] as any

    if (value instanceof FileList) {
      if (value.length === 0) {
        formData.append(`${key}_destroy`, 'true')
      } else {
        formData.append(key, value[0])
      }
    } else {
      formData.append(key, value)
    }
  }

  return formData
}

export const sanitizeRequestFields = (object: GenericObject) => {
  const disallowedKeys = ['_dirty']
  return objectRemoveEmpty(objectRemoveByKeys(disallowedKeys, object))
}

export const sleep = async (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const sortDisabledToEnd = (a: any, b: any) => +a?.disabled - +b?.disabled

export const sortAlphabeticallyAsc = (a: any, b: any) => {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

export const sortAlphabeticallyAscByAttr =
  (attr: string) => (a: any, b: any) => {
    if (a[attr] < b[attr]) {
      return -1
    }
    if (a[attr] > b[attr]) {
      return 1
    }
    return 0
  }
