interface LoadingProps {
  text?: string
}

const Loading = ({ text }: LoadingProps) => {
  return (
    <div style={{ fontSize: '16px', fontFamily: 'sans-serif' }}>
      {text ? text : 'Cargando...'}
    </div>
  )
}

export default Loading
